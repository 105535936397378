import { defineStore } from 'pinia'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    _userStoreFilled: false,
    id: null,
    username: null,
    first_name: null,
    last_name: null,
    avatar: null,
    settings: null,
    companies: null
  }),
  getters: {
    user: (state) => {
      if(state._userStoreFilled)
        return {
        id: state.id,
          username: state.username,
          first_name: state.first_name,
          last_name: state.last_name,
          avatar: state.avatar,
          settings: state.settings,
          companies: state.companies
        }
      else
        return null
    }
  },
  actions: {
    async changeUserSetting(userSetting, value) {
      this.settings[userSetting] = value

      // change in database
      const supabase = useSupabaseClient()
      const { error, data } = await supabase
        .from('users')
        .update({ settings: this.settings })
        .eq('id', this.id)

      if(error) {
        console.error(error)
      }
    },
    setUserSettings(userSettings) {
      if(!userSettings) {
        // standard settings
        return {
          darkMode: false, //TODO: refactor why appStore also has dark mode?? Maybe only use this.
          notificationSound: 4
        }
      } else return userSettings
    },
    setUser(userData) {
      this.id = userData.id
      this.username = userData.username
      this.first_name = userData.first_name
      this.last_name = userData.last_name
      this.avatar = userData.avatar
      this.companies = userData.companies
      this.settings = this.setUserSettings(userData.settings)
      this._userStoreFilled = true
    },
    async fetchUser() {
      return new Promise(async (resolve, reject) => {
        if (this._userStoreFilled) {
          resolve(true)
        } else {
          const supabase = useSupabaseClient()

          supabase.auth.onAuthStateChange(async (_event, session) => {
            if (session?.user) {
              const { data: userData } = await supabase
                .from('users')
                .select('id, username, first_name, last_name, avatar, settings, companies ( id, name, logo )')
                .eq('id', session?.user?.id)
                .single()

              if (userData) {
                this.setUser(userData)
                resolve(true)
              } else {
                reject(false)
              }
            }
          });
        }
      });
    },
    purgeUserStore() {
      this._userStoreFilled = false
      this.id = null
      this.username = null
      this.first_name = null
      this.last_name = null
      this.avatar = null
      this.settings = null
      this.companies = null
    }
  }
})
