// store/index.js

import { defineStore } from 'pinia'

export const useUserCreationStore = defineStore('userCreationStore', {
  state: () => ({
    signupStep: 1,
    signupStepFetched: false,
    priceId: null,
    coupon: null
  }),
  actions: {
    purgeUserCreationStore() {
      this.signupStep = 1
      this.signupStepFetched = false
      this.priceId = null
      this.coupon = null
    },
    async setSignupStep(step, priceId = null, coupon = null) {
      console.log('setSignupStep called with:', { step, priceId, coupon })
      
      const supabase = useSupabaseClient()
      const user = useSupabaseUser()
      this.signupStep = step
      this.priceId = priceId
      this.coupon = coupon

      // set in database (update or new)
      const { error, data } = await supabase
        .from('user_creation')
        .upsert({ 
          user_id: user.value.id, 
          current_step: step,
          price_id: priceId,
          coupon: coupon
        }, { onConflict: 'user_id' });

      if(error) {
        console.error('Error updating user_creation:', error)
      } else {
        console.log('Successfully updated user_creation:', data)
      }
    },
    async getSignupStep() {
      const supabase = useSupabaseClient()
      const user = useSupabaseUser()

      if(!user || !user.value) return 1
      if(this.signupStepFetched) return this.signupStep
      else {
        // get from database
        const { data, error } = await supabase
          .from('user_creation')
          .select('current_step, price_id, coupon')
          .eq('user_id', user.value.id)
          .single()

        if (error) {
          this.signupStep = null
        }

        if (data) {
          this.signupStep = data.current_step || null
          this.priceId = data.price_id || null
          this.coupon = data.coupon || null
        } else {
          this.signupStep = null
          this.priceId = null
          this.coupon = null
        }
      }

      this.signupStepFetched = true
      return this.signupStep
    }
  }
})
