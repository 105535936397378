import { default as confirmxRybVkSfBfMeta } from "/vercel/path0/pages/account/confirm.vue?macro=true";
import { default as createCYS3C1vdopMeta } from "/vercel/path0/pages/account/create.vue?macro=true";
import { default as logintl4upZrhATMeta } from "/vercel/path0/pages/account/login.vue?macro=true";
import { default as reset_45passwordOAVARxO1N9Meta } from "/vercel/path0/pages/account/reset-password.vue?macro=true";
import { default as resetgss37pq5lzMeta } from "/vercel/path0/pages/account/reset.vue?macro=true";
import { default as signupXAxOgvkxiuMeta } from "/vercel/path0/pages/account/signup.vue?macro=true";
import { default as trialVJRVLUZXgzMeta } from "/vercel/path0/pages/account/trial.vue?macro=true";
import { default as errorTD3gxBm2RgMeta } from "/vercel/path0/pages/error.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as cancelrd10GAry1fMeta } from "/vercel/path0/pages/payment/cancel.vue?macro=true";
import { default as successyZ3B4PpEueMeta } from "/vercel/path0/pages/payment/success.vue?macro=true";
import { default as billinglb9Lga5AxiMeta } from "/vercel/path0/pages/settings/billing.vue?macro=true";
import { default as companype5hlFZV1dMeta } from "/vercel/path0/pages/settings/company.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as preferencesiZk4GzZcfPMeta } from "/vercel/path0/pages/settings/preferences.vue?macro=true";
import { default as super_45adminEAeFsHC4yPMeta } from "/vercel/path0/pages/settings/super-admin.vue?macro=true";
import { default as maintenanceJImmUGzmVsMeta } from "/vercel/path0/pages/status/maintenance.vue?macro=true";
export default [
  {
    name: "account-confirm",
    path: "/account/confirm",
    component: () => import("/vercel/path0/pages/account/confirm.vue")
  },
  {
    name: "account-create",
    path: "/account/create",
    component: () => import("/vercel/path0/pages/account/create.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    component: () => import("/vercel/path0/pages/account/login.vue")
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    component: () => import("/vercel/path0/pages/account/reset-password.vue")
  },
  {
    name: "account-reset",
    path: "/account/reset",
    component: () => import("/vercel/path0/pages/account/reset.vue")
  },
  {
    name: "account-signup",
    path: "/account/signup",
    component: () => import("/vercel/path0/pages/account/signup.vue")
  },
  {
    name: "account-trial",
    path: "/account/trial",
    component: () => import("/vercel/path0/pages/account/trial.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/vercel/path0/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "payment-cancel",
    path: "/payment/cancel",
    component: () => import("/vercel/path0/pages/payment/cancel.vue")
  },
  {
    name: "payment-success",
    path: "/payment/success",
    component: () => import("/vercel/path0/pages/payment/success.vue")
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    component: () => import("/vercel/path0/pages/settings/billing.vue")
  },
  {
    name: "settings-company",
    path: "/settings/company",
    component: () => import("/vercel/path0/pages/settings/company.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings/index.vue")
  },
  {
    name: "settings-preferences",
    path: "/settings/preferences",
    component: () => import("/vercel/path0/pages/settings/preferences.vue")
  },
  {
    name: "settings-super-admin",
    path: "/settings/super-admin",
    meta: super_45adminEAeFsHC4yPMeta || {},
    component: () => import("/vercel/path0/pages/settings/super-admin.vue")
  },
  {
    name: "status-maintenance",
    path: "/status/maintenance",
    component: () => import("/vercel/path0/pages/status/maintenance.vue")
  }
]