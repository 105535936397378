import { useUserCreationStore } from '~/store/userCreation.js'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // Maintenance check (aMaintenance.global.js)
  const config = useRuntimeConfig()
  const isMaintenance = config.public.isMaintenance
  if (isMaintenance || isMaintenance === 'true') {
    const allowedRoutes = ['/status/maintenance']
    if (!allowedRoutes.includes(to.path)) {
      return navigateTo('/status/maintenance')
    }
  } else if ((!isMaintenance || isMaintenance === 'false') && to.path === '/status/maintenance') {
    return navigateTo('/')
  }

  // User creation guard (bUserCreationGuard.global.js)
  const user = useSupabaseUser()
  const store = useUserCreationStore()
  const step = await store.getSignupStep()

  if (to.path === '/account/signup') {
    if (user && user.value) {
      if ((!step || step === 4) && user.value.user_metadata.company_id) {
        return navigateTo('/')
      } else if (step) {
        return
      }
    }
  }

  if (user && user.value && step && step !== 4) {
    if (to.path !== '/payment/success' && to.path !== '/payment/cancel') {
      return navigateTo('/account/signup')
    } else {
      return
    }
  }

  // Billing check (cCheckBilling.global.js)
  if(user && user.value) {
    // allow logging out only
    if (to.path === '/account/logout' || to.path === '/error' || to.path === '/settings/billing') {
      return
    }

    const supabase = useSupabaseClient()
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('company_id')
      .eq('id', user.value.id)
      .single()

    if (userError) {
      console.error('Error fetching user data:', userError)
      return navigateTo('/error?reason=fetching-user-data')
    }

    const { data: billingData, error: billingError } = await supabase
      .from('billing')
      .select('billing_cycle_end, grace_period_use, grace_period_access, billing_type, prompt_limit_per_cycle, billing_cycle_start')
      .eq('company_id', userData.company_id)
      .single()

    if (billingError) {
      console.error('Error fetching billing data:', billingError)
      return navigateTo('/error?reason=fetching-billing-data')
    }

    if (!userData || !billingData) {
      return navigateTo('/error?reason=fetching-billing-data')
    }

    const currentDate = new Date()
    if(billingData.billing_type !== 'trial') {

      const gracePeriodEnd = new Date(billingData.billing_cycle_end)
      gracePeriodEnd.setDate(gracePeriodEnd.getDate() + billingData.grace_period_access)

      // Check if the user is an admin
      const { data: userRoleData } = await supabase.rpc('get_my_claims')
      const isAdmin = userRoleData.app_role === 'admin'

      if (currentDate > gracePeriodEnd) {
        if (isAdmin) {
          return navigateTo('/settings/billing')
        } else {
          return navigateTo('/error?reason=billing-issue')
        }
      }
    } else if (billingData.billing_type === 'trial') {
      // Check if the billing type is trial and usage period is over

      const usageGracePeriodEnd = new Date(billingData.billing_cycle_end)
      usageGracePeriodEnd.setDate(usageGracePeriodEnd.getDate()) // no grace period for trial

      if (currentDate > usageGracePeriodEnd) {
        return navigateTo('/error?reason=trial-expiry-reached')
      }

      // Check if prompt limit has been reached
      const { count, error: countError } = await supabase
        .from('messages_log')
        .select('id', { count: 'exact' })
        .eq('company_id', userData.company_id)
        .eq('role', 'ai')
        .gte('created_at', billingData.billing_cycle_start)
        .lte('created_at', billingData.billing_cycle_end)

      if (countError) {
        console.error('Error fetching prompt count:', countError)
        return navigateTo('/error?reason=fetching-billing-data')
      }

      const usedPrompts = count || 0
      if (usedPrompts >= billingData.prompt_limit_per_cycle) {
        return navigateTo('/error?reason=trial-limit-reached')
      }
    }
  }
})