import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import routing_45guard_45global from "/vercel/path0/middleware/routingGuard.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  routing_45guard_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "super-admin-guard": () => import("/vercel/path0/middleware/superAdminGuard.js")
}